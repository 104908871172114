<template>
  <div>
    <vs-popup
      fullscreen
      class="holamundo"
      title="Envoi d'avis"
      :active.sync="popupActiveSendSouscription"
    >
      <div class="aps-benin-v2" v-if="crrID.id">
        <vs-row vs-w="12">
          <vs-col
            class="pt-3 pr-4"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-lg="6"
            vs-sm="6"
            vs-xs="12"
          >
            <div class="w-full" v-if="crrID.subscription">
              <div>
                <strong>Information du client :</strong>
                {{ crrID.subscription.subscriber.firstname }}
                {{ crrID.subscription.subscriber.lastname }}
              </div>
            </div>
          </vs-col>
          <vs-col
            class="pt-3 pr-4"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-lg="6"
            vs-sm="6"
            vs-xs="12"
          >
            <div class="w-full" v-if="crrID.broker">
              <div>
                <strong>Intermédiaire actuel :</strong>
                {{ crrID.broker.person.firstname }}
                {{ crrID.broker.person.lastname }}
              </div>
            </div>
          </vs-col>
        </vs-row>
        <vs-divider />
        <vs-row vs-w="12" class="w-full">
          <vs-col
            class="pt-3 pr-4 w-full"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-lg="6"
            vs-sm="6"
            vs-xs="12"
          >
            <div class="w-full">
              <div class="mb-4">Avis de souscription*</div>
              <vs-row>
                <ul class="centerx" style="column-count: 2">
                  <vs-col class="pb-2" vs-lg="12" vs-sm="12" vs-xs="12">
                    <li v-for="(sousc, index) in noticeList" :key="index">
                      <vs-radio
                        v-model="formSend.status"
                        :vs-value="sousc.id"
                        >{{ sousc.label }}</vs-radio
                      >
                    </li>
                  </vs-col>
                </ul>
              </vs-row>
            </div>
          </vs-col>
        </vs-row>
        <div class="mt-8" />
        <vs-row v-if="getDefaultPayment.isDefault == true && formSend.status == 'validationWithExtraPremium'">
          <vs-col
            class="pr-4"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-lg="4"
            vs-sm="4"
            vs-xs="12"
          >
            <div class="w-full">
              <vs-input
                type="number"
                class="aps-benin-v2-form-number inputx w-full mt-2 mb-2"
                label="Cotisation TTC*"
                v-model="formSend.cotisationTTC"
                v-validate="'required|numeric'"
                data-vv-validate-on="blur"
                name="Cotisation TTC"
                :min="getDefaultPayment.cotisationTTC"
              />
              <div class="aps-benin-v2-error-msg">
                {{ errors.first("Cotisation TTC") }}
              </div>
              <div class="aps-benin-v2-error-msg" v-if="parseInt(formSend.cotisationTTC) <= parseInt(getDefaultPayment.cotisationTTC)">
                La cotisation TTC doit être supérieure à {{getDefaultPayment.cotisationTTC}} F CFA
              </div>
            </div>
          </vs-col>
        </vs-row>
        <vs-row vs-w="12">
          <vs-col
            class="pt-3 pr-4"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-lg="12"
            vs-sm="12"
            vs-xs="12"
            style="flex-direction: column"
          >
            <div class="w-full">
              <p class="pl-2 pt-2">Objet*</p>
              <multiselect
                v-model="variableGlobalFormOject"
                :options="allMailVariableList"
                class="mb-4 mt-2"
                id="text"
                placeholder="--Insérer une variable de personnalisation--"
                label="label"
                track-by="label"
                :selectLabel="global.selectLabel"
                :selectedLabel="global.selectedLabel"
                :deselectLabel="global.deselectLabel"
              >
              </multiselect>

              <vs-input
                class="inputx w-full"
                id="texto"
                @change="onEditorChangeFormObjet($event)"
                v-model="formSend.object"
                v-validate="'required'"
                data-vv-validate-on="blur"
                name="Objet"
              />
              <div class="aps-benin-v2-error-msg">{{ errors.first("Objet") }}</div>
            </div>
          </vs-col>
          <vs-col
            class="pr-4 w-full mt-4 mb-3"
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-lg="12"
            vs-sm="12"
            vs-xs="12"
            style="flex-direction: column"
          >
            <div class="pl-2 mt-2">Message*</div>

            <div class="w-full">
              <multiselect
                v-model="variableGlobal"
                :options="allMailVariableList"
                class="mb-4"
                placeholder="--Insérer une variable de personnalisation--"
                label="label"
                track-by="label"
                :selectLabel="global.selectLabel"
                :selectedLabel="global.selectedLabel"
                :deselectLabel="global.deselectLabel"
              ></multiselect>
              <quill-editor
                @focus="onEditorFocusForm($event)"
                @change="onEditorChangeForm($event)"
                v-model="formSend.message"
              ></quill-editor>
            </div>
          </vs-col>
        </vs-row>

        <vs-row vs-w="12">
          <vs-col class="pr-4 mb-2 mt-8" vs-lg="12" vs-sm="12" vs-xs="12">
            <div
              class="w-full"
              style="
                display: flex;
                align-items: flex-end;
                justify-content: space-between;
              "
            >
              <div>
                <div>
                  <span class="ml-2">Fichier(s)</span>
                  <div>
                    <div uk-form-custom class="ebsi-upload-file">
                      <input
                        multiple
                        type="file"
                        :value="resetFilesUpload"
                        v-on:change="checkUploadFile"
                      />
                      <button
                        style="cursor: pointer"
                        class="uk-button uk-button-default"
                        type="button"
                        tabindex="-1"
                      >
                        Joindre des fichiers
                      </button>
                    </div>
                  </div>
                </div>

                <div class="aps-benin-v2-error-msg" v-show="formError">
                  Erreur lors du chargement du fichier
                </div>
              </div>
            </div>

            <div
              class="mt-3 mb-3"
              style="display: flex; flex-direction: column"
            >
              <div class="mb-4" v-if="formSend.filesname.length > 0">
                <p>
                  <b>Liste des fichiers</b>
                </p>
              </div>
              <div
                v-for="(file, index) in formSend.filesname"
                :key="file.name"
                class="center"
              >
                <div class="flex" v-if="file.filename && file.name">
                  {{ index + 1 }})
                  <b @click="checkViewFile(file)" class="pl-2 cursor-pointer">
                    {{ subString(file.name) }}
                  </b>
                  <vx-tooltip color="primary" text="Supprimer">
                    <div
                      class="ml-8 cursor-pointer"
                      @click="removeFileFromFilenameDuplicated(index)"
                    >
                      <feather-icon
                        icon="Trash2Icon"
                        svgClasses="w-6 h-6"
                      ></feather-icon>
                    </div>
                  </vx-tooltip>
                </div>
                <div v-else class="flex">
                  {{ index + 1 }})
                  <b>{{ subString(file) }}</b>
                  <vx-tooltip color="primary" text="Supprimer">
                    <div
                      class="ml-8 cursor-pointer"
                      @click="removeFile(index, 'Duplicated')"
                    >
                      <feather-icon
                        icon="Trash2Icon"
                        svgClasses="w-6 h-6"
                      ></feather-icon>
                    </div>
                  </vx-tooltip>
                </div>
              </div>
            </div>
          </vs-col>
        </vs-row>
        <div class="pt-2 pb-2">
          <div class="inline-flex">
            <div>
              <vs-button
                :disabled="
                  !validateFormSendSuscription || loadingAddSendSuscription
                "
                @click="addSubscriptioNotice"
                class="w-full sm:w-auto"
                >Envoyer</vs-button
              >
              <vs-button
                class="w-full sm:w-auto ml-4"
                type="border"
                @click="popupActiveSendSouscription = false"
                >Annuler</vs-button
              >
            </div>

            <div class="w-auto" v-if="loadingAddSendSuscription">
              <clip-loader
                :loading="loadingAddSendSuscription"
                color="primary"
              ></clip-loader>
            </div>
          </div>
          <div class="mt-4">
            <p class="text-danger">* Champ obligatoire</p>
          </div>
        </div>
      </div>
    </vs-popup>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { en, fr } from "vuejs-datepicker/dist/locale";
import global from "@/config/global";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import Multiselect from "vue-multiselect";

import { mixin } from "@/mixins/mixin";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import moment from "@/helpers/moment";


export default {
  name: "aps-benin-v2-subscribe-send",
  props: {
    crrModuleName: String,
    crrID: Object,
    isClo: Boolean,
  },
  data() {
    return {
      cursorPositionObject: 0,
      formTakeOjet: {},
      variableGlobalFormOject: "",
      fileData: [],
      global: {},
      loadingAddSendSuscription: false,
      loadingDownloadFile: false,
      etat: false,
      popupActiveSendSouscription: false,
      resetFilesUpload: "",
      variableGlobal: "",
      formData: [],
      formError: false,
      getDefaultPayment: {},
      formSend: {
        status: "",
        cotisationTTC: "",
        object: "",
        message: "",
        filesname: [],
      },
      quillEditorForm: {},
      variableGlobalForm: "",
      cursorPositionForm: 0,
    };
  },
  created() {
    //this.role = role;
    this.global = global;
    Validator.localize("fr", VeeValidateFR);
    console.log(this.crrID, "----");
  },
  mounted() {
  },
  components: {
    ClipLoader,
    Multiselect,
    quillEditor,
  },
  computed: {
    ...mapGetters("general", [
      "rowsTable",
      "noticeList",
      // "applyUploadFile",
      "allMailVariableList",
      "allMailVariableText",
      "subscriptionNotice",
      "allTyeMailAdditionalInfoForIntermediary",
    ]),
    ...mapGetters("suscribe", ["allProjects", "getSupInfoObject"]),

    validateFormSendSuscription() {
      if(this.formSend.status === "validationWithExtraPremium") {
        return (
          !this.errors.any() &&
          this.formSend.object != "" &&
          this.formSend.status != "" &&
          parseInt(this.formSend.cotisationTTC) > parseInt(this.getDefaultPayment.cotisationTTC) &&
          this.formSend.message != ""
        )
      } else {
        return (
          !this.errors.any() &&
          this.formSend.object != "" &&
          this.formSend.status != "" &&
          this.formSend.message != ""
        )
      }
    },
  },
  methods: {
    ...mapActions("general", ["applyUploadFile", "applyGetSetting", "applyGenerateCotisation"]),
    ...mapActions("suscribe", ["applyPutProject", "applySentProject", "applyPutAvenant", "applyPutQuittance", "applyPutAllQuittances"]),
    checkValidationWithExtraPremium(project) {
      if(project.productName == "SOHU") {
        this.getDefaultPayment = project.getDefaultAvenant
      }

      this.formSend.cotisationTTC = this.getDefaultPayment.cotisationTTC
    },
    /* Form Objet */
    getInputSelection(el) {
      let start = 0,
        end = 0,
        normalizedValue,
        range,
        textInputRange,
        len,
        endRange;

      if (
        typeof el.selectionStart == "number" &&
        typeof el.selectionEnd == "number"
      ) {
        start = el.selectionStart;
        end = el.selectionEnd;
      } else {
        range = document.selection.createRange();

        if (range && range.parentElement() == el) {
          len = el.value.length;
          normalizedValue = el.value.replace(/\r\n/g, "\n");

          textInputRange = el.createTextRange();
          textInputRange.moveToBookmark(range.getBookmark());

          endRange = el.createTextRange();
          endRange.collapse(false);

          if (textInputRange.compareEndPoints("StartToEnd", endRange) > -1) {
            start = end = len;
          } else {
            start = -textInputRange.moveStart("character", -len);
            start += normalizedValue.slice(0, start).split("\n").length - 1;

            if (textInputRange.compareEndPoints("EndToEnd", endRange) > -1) {
              end = len;
            } else {
              end = -textInputRange.moveEnd("character", -len);
              end += normalizedValue.slice(0, end).split("\n").length - 1;
            }
          }
        }
      }

      return {
        start: start,
        end: end,
      };
    },
    onEditorChangeFormObjet(position) {
      let input = document.getElementById("texto");
      input.focus();
      this.cursorPositionObject = this.getInputSelection(input).start;
      this.formTakeOjet = position;
    },
    insertTextFormObjet(data) {
      this.formSend.object = [
        this.formSend.object.slice(0, this.cursorPositionObject),
        `${data} `,
        this.formSend.object.slice(this.cursorPositionObject),
      ].join("");
      this.cursorPositionObject = this.formSend.object.length;
    },
    /* End Form Objet */

    /* Form */

    onEditorChangeForm(quill) {
      this.cursorPositionForm = quill.quill.getSelection(quill).index;
      this.quillEditorForm = quill.quill;
    },
    onEditorFocusForm(quill) {
      this.cursorPositionForm = quill.getSelection();
      this.quillEditorForm = quill;
    },
    insertTextForm(data) {
      this.quillEditorForm.insertText(this.cursorPositionForm, `${data} `);
      this.cursorPositionForm = this.formSend.message.length - 7;
      console.log("this.quillEditorForm", this.quillEditorForm);
    },

    /* End Form */

    acceptAlert(data) {
      let newdata = { ...data };
      this.$vs.notify(newdata);
    },

    ///  fichier ///
    removeFile(data) {
      this.formData.splice(data, 1);
      this.formSend.filesname.splice(data, 1);
    },
    subString(data) {
      if (data.length > 40) {
        return `${data.substring(0, 40)} ...`;
      } else {
        return data;
      }
    },
    checkUploadFile(evt) {
      const evtLength = evt.target.files.length;
      let allFilesChecked = [];
      let formDataLength = this.formData.length;
      for (let i = 0; i < evtLength; i++) {
        let formDataTmp = new FormData();
        formDataTmp.append("uri", evt.target.files[i]);
        this.formData[formDataLength + 1] = formDataTmp;
        this.formError = false;
        allFilesChecked.push(evt.target.files[i].name);
        formDataLength++;
      }
      if (this.formSend.filesname) {
        this.formSend.filesname = [
          ...this.formSend.filesname,
          ...allFilesChecked,
        ];
      } else {
        this.formSend.filesname = [...allFilesChecked];
      }
    },
    async uploadedFiles() {
      let _this = this;
      let productPromises = [];
      let success = false;
      this.formData.forEach(function (ele, index) {
        productPromises.push(
          (async function (ele) {
            const res = await _this.applyUploadFile(ele);
            _this.fileData.push({
              filename: res.data[0].uploadedName,
              name: res.data[0].filename,
            });
            if (res.status === "success") {
              success = true;
            }
          })(ele) 
        );
      });
      await Promise.all(productPromises);
      return success;
    },
    async addSubscriptioNotice() {
      this.loadingAddSubscribe = true;
      if (!this.validateFormSendSuscription) {
        return;
      }
      this.loadingAddSendSuscription = true;

      
      let uploadState = false;
      if (this.formSend.filesname.length > 0) {
        try {
          uploadState = await this.uploadedFiles();
          this.acceptAlert(
            mixin.methods.message("subscription", "file").success
          );
        } catch (error) {
          uploadState = false;
          this.formSend = {
            status: "",
            object: "",
            message: "",
            filesname: [],
          };
          this.loadingAddSubscribe = false;

          this.popupActiveSendSouscription = false;

          this.acceptAlert(mixin.methods.message("subscription", "file").error);
        }
      } else {
        uploadState = true;
      }

      if (uploadState) {
        await this.applyPutProject({
          ...this.crrID,
          projectId: this.crrID.id,
          notice: this.formSend.status,
          quote: this.crrID.quote ? this.crrID.quote.id : "",
          subscription: this.crrID.subscription
            ? this.crrID.subscription.id
            : "",
          client: this.crrID.client ? this.crrID.client.id : "",
          broker: this.crrID.broker ? this.crrID.broker.id : "",
          differentiate: "sendSubscriptionNotice",
          issueContractAt: (this.formSend.status === "standardValidation" || this.formSend.status === "validationWithExtraPremium") ? moment(new Date()).format("YYYY-MM-DD") : null
        });

        let gCotisation
        if(this.crrID.productName == "SOHU") {
          if(this.formSend.status === "validationWithExtraPremium") {
            // Appliquer la nouvelle cotisationTTC à l'avenant 0 
            await this.applyPutAvenant({
              avenantId: this.getDefaultPayment.id,
              ...this.getDefaultPayment,
              cotisationTTC: this.formSend.cotisationTTC,
              paymentAmount: this.formSend.cotisationTTC,
            })
          }
          if(this.formSend.status === "standardValidation" || this.formSend.status === "validationWithExtraPremium") {
            gCotisation =  await this.applyGenerateCotisation({ projectId: this.crrID.id, avenantId: this.getDefaultPayment.id }) // Avenant 0
          }
        }

        let filesname = [ ...this.fileData ]
        if(gCotisation) {
          filesname = [
            ...filesname,
            {
              name: gCotisation.data.docClient.name+".pdf",//,
              filename: gCotisation.data.currPdfName,
            },
          ]
        }
        let emailData = {
          who: "medecin",
          object: this.formSend.object,
          message: `${this.formSend.message}`,
          filesname,
          subscriberId: this.crrID.subscription
            ? this.crrID.subscription.subscriber.id
            : "",
          projectId: this.crrID.id,
          brokerId: this.crrID.broker ? this.crrID.broker.id : "",
        };

        if (this.formSend.status === "fullInfo") {
          emailData = {...emailData, brokerId: ""}
        }
        if (this.formSend.status === "medicalVisit") {
          emailData = {...emailData, who: "", itIs_MedicalVisit: true}
        }

        setTimeout(async () => {
          await this.applySentProject(emailData);

          if (this.formSend.status === "fullInfo") {
            // Get Infos. Comp. Mail client
            let res = await this.applyGetSetting({
              name: this.allTyeMailAdditionalInfoForIntermediary[
                this.crrID.productName
              ],
            }); 
            let emailBrokerData = { 
              subscriberId: this.crrID.subscription
                ? this.crrID.subscription.subscriber.id
                : "",
              projectId: this.crrID.id,
              notAdhesion: true,
              a: this.crrID.broker.email, // Broker Email Here
              object: res.data.value.object,
              message: res.data.value.message,
              filesname: res.data.value.filesname,
            };

            await this.applySentProject(emailBrokerData);
          }

          this.$emit("onListenSend");
          this.acceptAlert(
            mixin.methods.message("subscription", "sendNotice").success
          );

          this.fileData = [];
          this.formData = [];
          this.formSend = {
            status: "",
            object: "",
            message: "",
            filesname: [],
          };
          this.loadingAddSendSuscription = false;
        }, 300)
      }
    },
    async fetchApplySendSouscriptionNotice() {
      this.loadingAddSubscribe = true;
      if (!this.validateFormComment) return false;
      try {
        let data = {
          id: this.item.id,
          content: this.formComment.message,
          filesname: this.fileData,
          firstname: this.user.person.firstname,
          lastname: this.user.person.lastname,
        };
        await this.applyAddComment(data);
        this.loadingAddSubscribe = false;

        this.acceptAlert("success", "Ajout", "Commentaire envoyé");

        this.formComment = {};
        this.isClose = false;
      } catch (err) {
        this.loadingAddSubscribe = false;
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach((item) => {
              switch (item.code) {
                // case "userNotFound":
                //   this.errorMsg = "Votre nom d'utilisateur n'existe pas";
                //   this.acceptAlert("danger", "Utilisateurs", this.errorMsg);
                //   break;

                default:
                  this.acceptAlert(
                    "danger",
                    "Utilisateurs",
                    "Une erreur s'est produite"
                  );
                  break;
              }
            });
          }
        }
      }
    },
  },
  watch: {
    "formSend.status"(val) {
      this.formSend.object = this.getSupInfoObject[val];
    },
    variableGlobalFormOject() {
      if (this.formTakeOjet) {
        this.insertTextFormObjet(this.variableGlobalFormOject.id);
      }
    },
    variableGlobal() {
      if (this.quillEditorForm.selection) {
        this.insertTextForm(this.variableGlobal.id);
      }
    },
    popupActiveSendSouscription() {
      if (!this.popupActiveSendSouscription) {
        this.$emit("onListenSend", false);
      }
    },
    isClo() {
      this.checkValidationWithExtraPremium(this.crrID)
      this.popupActiveSendSouscription = this.isClo;
    },
  },
};
</script>
<style>
.aps-benin-v2 {
  margin-left: 4vw;
  margin-right: 4vw;
}
</style>
